/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import FormattedMessageJamezz from "./FormattedMessageJamezz";
import { useAppDispatch, useAppSelector } from "../utils/redux/store";
import { setHasInternetConnection } from "../utils/redux/globalSlice";

export default function InternetConnection() {
  const isConnected = useAppSelector((state) => state.global.hasInternetConnection);
  const dispatch = useAppDispatch();
  // allow users to change its position by clicking.
  // it covers buttons behind it which become unclickable
  const [positionTop, setPositionTop] = useState(true);

  useEffect(() => {
    window.addEventListener("online", handleInternetConnectionChange);
    window.addEventListener("offline", handleInternetConnectionChange);
    return () => {
      window.removeEventListener("offline", handleInternetConnectionChange);
      window.removeEventListener("online", handleInternetConnectionChange);
    };
  }, []);

  const handleInternetConnectionChange = useCallback(() => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            dispatch(setHasInternetConnection(true));
            clearInterval(webPing);
          })
          .catch(() => dispatch(setHasInternetConnection(false)));
      }, 2000);
      return;
    }

    dispatch(setHasInternetConnection(false));
  }, [dispatch]);

  return (
    <Snackbar
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
      }}
      anchorOrigin={{ vertical: positionTop ? "top" : "bottom", horizontal: "center" }}
      open={!isConnected}
      onClose={() => {}}
      onClick={() => {
        setPositionTop((prev) => !prev);
      }}
    >
      <Alert severity={"error"}>
        <FormattedMessageJamezz id={"internet_connection_lost"} />
      </Alert>
    </Snackbar>
  );
}
